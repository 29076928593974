import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'urql'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { graphql } from '@/__generated__/gql'
import { PaymentMethod } from '@/__generated__/gql/graphql'
import { formatCurrency } from '@/utils/format-currency'
import { formatDate } from '@/utils/format-date'
import { phrases } from '@/utils/phrases'
import { cn } from '@/utils/cn'
import { useQueryParams } from '@/hooks/use-query-params'
import { createPaymentIntentDocument } from '@/operations/create-payment-intent'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Dd, Dl, Dt } from '@/components/ui/dl'
import { Alert } from '@/components/ui/alert'
import { TargetGroupHeading } from '@/components/target-group-heading'
import { BottomNavbar } from '@/components/bottom-navbar'
import { Card } from '@/components/ui/card'
import { PaymentElement } from '@/components/payment-element'
import { useToast } from '@/components/ui/use-toast'
import { BookingCardHeader } from '../components/booking-card-header'
import { EditBooking } from '../components/edit-booking'

const bookingDocument = graphql(`
  query booking($id: ID!, $signature: String!) {
    booking(id: $id, signature: $signature) {
      id
      number
      firstName
      lastName
      dob
      gender
      friends
      notes
      pastVisits
      sweaterSize
      creditLimit
      status
      term {
        id
        targetGroup
        startsAt
      }
      order {
        id
      }
      reservation {
        id
        accepted
      }
      depositPayment {
        id
        state
        amount
        updatedAt
        data {
          reference
        }
      }
      ...BookingCardHeader
    }
  }
`)

export const BookingRoute = () => {
  const { toast } = useToast()
  const params = useParams<'signature' | 'bookingId'>()
  const [queryParams, setQueryParams] = useQueryParams({ section: 'booking' })
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.Vipps)
  const [{ data, fetching, error }] = useQuery({
    query: bookingDocument,
    variables: { id: params.bookingId!, signature: params.signature! },
  })
  const [, createPaymentIntent] = useMutation(createPaymentIntentDocument)

  const handlePayment = async () => {
    if (!data) return
    const res = await createPaymentIntent({
      input: {
        paymentMethod,
        orderId: data.booking.order.id,
        bookings: [
          {
            id: data.booking.id,
            termId: data.booking.term.id,
          },
        ],
      },
    })
    if (res.error || !res.data?.createPaymentIntent.redirectUrl) {
      console.error(error || new Error('No redirectUrl retrieved'))
      toast({
        ...phrases.error,
        variant: 'destructive',
      })
      return
    }
    window.location.href = res.data.createPaymentIntent.redirectUrl
  }

  if (fetching) return <Loading />
  if (error) return <QueryError error={error} />
  if (!data) return null

  const booking = data.booking

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-3xl font-bold text-center">
        Booking{' '}
        <span className="border-b-4 border-light-blue inline-block h-[2.05rem]">
          #{booking.number}
        </span>
      </h1>

      <TargetGroupHeading targetGroup={booking.term.targetGroup} />

      <Card
        className={cn(
          booking.reservation.accepted && 'bg-light-blue-2',
          !booking.reservation.accepted && 'bg-light-purple'
        )}
      >
        <BookingCardHeader booking={booking} />
      </Card>

      <Accordion
        collapsible
        type="single"
        disabled={!!queryParams.edit}
        value={queryParams.section}
        onValueChange={(section) =>
          setQueryParams((prev) => ({ ...prev, section }))
        }
      >
        <AccordionItem value="booking">
          <AccordionTrigger>Deltaker</AccordionTrigger>
          <AccordionContent>
            {!queryParams.edit && (
              <div className="text-right mb-3 border-b-2 border-dashed">
                <Button
                  size="sm"
                  variant="link"
                  className="p-0"
                  onClick={() =>
                    setQueryParams((prev) => ({ ...prev, edit: 'booking' }))
                  }
                >
                  <Pencil1Icon className="mr-2 h-4 w-4" />
                  Rediger
                </Button>
              </div>
            )}

            {queryParams.edit === 'booking' && (
              <EditBooking
                term={booking.term}
                onSuccess={() =>
                  setQueryParams((prev) => ({ ...prev, edit: undefined }))
                }
                defaultValues={{
                  firstName: booking.firstName,
                  lastName: booking.lastName,
                  dob: booking.dob,
                  gender: booking.gender,
                  pastVisits: booking.pastVisits,
                  friends: booking.friends ?? '',
                  notes: booking.notes ?? '',
                  sweaterSize: booking.sweaterSize,
                  creditLimit: booking.creditLimit,
                }}
              />
            )}

            {queryParams.edit !== 'booking' && (
              <Dl>
                <Dt>Navn</Dt>
                <Dd>
                  {booking.firstName} {booking.lastName}
                </Dd>
                <Dt>Fødselsdato</Dt>
                <Dd>{formatDate(booking.dob, { style: 'short' })}</Dd>
                <Dt>Kjønn</Dt>
                <Dd>{phrases.genders[booking.gender]}</Dd>
                <Dt>Ønsker å bo med</Dt>
                <Dd>{booking.friends || '-'}</Dd>
                <Dt>Særlige opplysninger</Dt>
                <Dd>{booking.notes || '-'}</Dd>
                <Dt>Deltatt tidligere (antall år)</Dt>
                <Dd>{booking.pastVisits}</Dd>
                <Dt>Genser</Dt>
                <Dd>{booking.sweaterSize?.toUpperCase() ?? 'Ingen'}</Dd>
                <Dt>Kioskpenger</Dt>
                <Dd>{booking.creditLimit ?? 'Ingen'}</Dd>
              </Dl>
            )}
          </AccordionContent>
        </AccordionItem>

        {booking.depositPayment &&
          ['pending', 'failed'].includes(booking.depositPayment.state) && (
            <AccordionItem value="payment">
              <AccordionTrigger>Betaling</AccordionTrigger>
              <AccordionContent>
                {booking.depositPayment.state === 'pending' && (
                  <p>Betaling utføres når plassen er bekreftet.</p>
                )}
                {booking.depositPayment.state === 'invoiced' && (
                  <Dl>
                    <Dt>Fakturert</Dt>
                    <Dd>{formatCurrency(booking.depositPayment.amount)}</Dd>
                    <Dt>Dato</Dt>
                    <Dd>{formatDate(booking.depositPayment.updatedAt)}</Dd>
                  </Dl>
                )}
                {booking.depositPayment.state === 'paid' && (
                  <Dl>
                    <Dt>Forhåndsbetalt</Dt>
                    <Dd>{formatCurrency(booking.depositPayment.amount)}</Dd>
                    <Dt>Dato</Dt>
                    <Dd>{formatDate(booking.depositPayment.updatedAt)}</Dd>
                  </Dl>
                )}
                {booking.depositPayment.state === 'failed' && (
                  <div className="flex flex-col gap-4">
                    <Alert variant="destructive">
                      Forhåndsbetaling for denne bookingen kunne ikke
                      gjennomføres. Vennligst betalt på nytt for å unngå
                      kansellering.
                    </Alert>
                    <PaymentElement
                      availablePaymentMethods={[
                        PaymentMethod.Vipps,
                        PaymentMethod.Card,
                      ]}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                    />

                    <Button onClick={handlePayment}>Betal</Button>
                  </div>
                )}
              </AccordionContent>
            </AccordionItem>
          )}
      </Accordion>

      <BottomNavbar className="justify-between">
        {queryParams.edit === 'booking' && (
          <>
            <Button
              variant="ghost"
              onClick={() =>
                setQueryParams((prev) => ({ ...prev, edit: undefined }))
              }
            >
              Avbryt
            </Button>
            <Button form="edit-booking-form">Lagre</Button>
          </>
        )}
        {!queryParams.edit && (
          <Button asChild variant="ghost">
            <Link to="..">Tilbake</Link>
          </Button>
        )}
      </BottomNavbar>
    </div>
  )
}
