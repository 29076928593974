import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { DeepPartial, useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { TargetGroup } from '@/__generated__/gql/graphql'
import { graphql } from '@/__generated__/gql'
import { formatDate } from '@/utils/format-date'
import { phrases } from '@/utils/phrases'
import { schemas } from '@/utils/schemas'
import { useDobValidator } from '@/hooks/use-dob-validator'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { GenderRadioGroup } from '@/components/gender-radio-group'
import { PastVisitsSelect } from '@/components/past-visits-select'

type Props = {
  defaultValues?: DeepPartial<FormValues>
  term: {
    targetGroup: TargetGroup
    startsAt: string
  }
  onSuccess: () => void
}

const formSchema = z.object({
  firstName: schemas.firstName,
  lastName: schemas.lastName,
  dob: schemas.dob,
  gender: schemas.gender,
  pastVisits: schemas.pastVisits,
  friends: schemas.friends,
  notes: schemas.notes,
  sweaterSize: schemas.sweaterSize,
  creditLimit: schemas.creditLimit,
})

const updateBookingDocument = graphql(`
  mutation UpdateBooking($id: ID!, $input: UpdateBookingInput!, $signature: String) {
    updateBooking(id: $id, input: $input, signature: $signature) {
      booking {
        id
        firstName
        lastName
        dob
        gender
        friends
        notes
        pastVisits
        sweaterSize
        creditLimit
      }
    }
  }
`)

export type FormValues = z.infer<typeof formSchema>

export const EditBooking = ({ defaultValues, term, onSuccess }: Props) => {
  const params = useParams<'signature' | 'bookingId'>()
  const { toast } = useToast()
  const [, updateBooking] = useMutation(updateBookingDocument)
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const { minDob, maxDob } = useDobValidator(form, term)

  const onInvalid = useCallback(() => {
    toast({
      description:
        'Noen obligatoriske felt mangler, vennligst se gjennom skjemaet.',
      variant: 'destructive',
    })
  }, [])

  const onSubmit = useCallback(
    async (input: FormValues) => {
      const res = await updateBooking({
        id: params.bookingId!,
        signature: params.signature,
        input,
      })
      if (res.error) {
        toast({
          title: phrases.error.title,
          description: res.error.message,
          variant: 'destructive',
        })
        return
      }
      toast({ title: 'Deltaker oppdatert' })
      onSuccess()
    },
    [updateBooking]
  )

  const { errors } = form.formState

  return (
    <Form {...form}>
      <form
        id="edit-booking-form"
        onSubmit={form.handleSubmit(onSubmit, onInvalid)}
        className="flex flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Fornavn</FormLabel>
              <FormControl>
                <Input {...field} isError={Boolean(errors.firstName)} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lastName"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Etternavn</FormLabel>
              <FormControl>
                <Input {...field} isError={Boolean(errors.lastName)} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="dob"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Fødselsdato</FormLabel>
              <FormDescription>
                Deltaker må være født mellom{' '}
                {formatDate(minDob, { style: 'short' })} og{' '}
                {formatDate(maxDob, { style: 'short' })}
              </FormDescription>
              <FormControl>
                <Input
                  min={minDob}
                  max={maxDob}
                  type="date"
                  {...field}
                  isError={Boolean(errors.dob)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="gender"
          render={({ field }) => (
            <FormItem>
              <Label required>Kjønn</Label>
              <FormControl>
                <GenderRadioGroup
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="pastVisits"
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Deltatt tidligere?</FormLabel>
              <FormDescription>
                Hvor mange år har deltaker deltatt før
              </FormDescription>
              <PastVisitsSelect value={field.value} onChange={field.onChange} />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="friends"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Ønsker å bo med</FormLabel>
              <FormDescription>Navn på noen du ønsker å bo med</FormDescription>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Særlige opplysninger</FormLabel>
              <FormDescription>
                Informasjon om deltaker som Oksnøen Leir bør vite om
              </FormDescription>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="creditLimit"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Kioskpenger</FormLabel>
              <FormDescription>
                Maks beløp deltakeren kan handle for i kiosken. Benyttet beløp
                etterfaktureres
              </FormDescription>
              <FormControl>
                <Input
                  {...field}
                  type="number"
                  min={0}
                  value={field.value ?? ''}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value ? Number(e.target.value) : null
                    )
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  )
}
