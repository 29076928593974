import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CombinedError, useMutation } from 'urql'
import { graphql } from '@/__generated__/gql'
import { PaymentMethod } from '@/__generated__/gql/graphql'
import { config } from '@/config'
import { useOnMount } from '@/hooks/use-on-mount'
import { phrases } from '@/utils/phrases'
import { sumBy } from '@/utils/sum-by'
import { formatCurrency } from '@/utils/format-currency'
import { createPaymentIntentDocument } from '@/operations/create-payment-intent'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { Content } from '@/components/layout'
import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { BottomNavbar } from '@/components/bottom-navbar'
import { PaymentElement } from '@/components/payment-element'
import { useOrderContext } from './components/order-context'
import { ExpiryNotice } from './components/expiry-notice'
import { InfoNotice } from './components/info-notice'

const storeOrderDocument = graphql(`
  mutation StoreOrder($input: StoreOrderInput!) {
    storeOrder(input: $input) {
      value
    }
  }
`)

export const Payment = () => {
  const { toast } = useToast()
  const navigate = useNavigate()
  const { state, dispatch, availablePaymentMethods, availableTerms } =
    useOrderContext()
  const [isLoading, setIsLoading] = useState(false)
  const [termsError, setTermsError] = useState('')
  const [, createPaymentIntent] = useMutation(createPaymentIntentDocument)
  const [, storeOrder] = useMutation(storeOrderDocument)

  useOnMount(() => {
    if (state.bookings.length === 0) {
      navigate('../bookings')
    } else if (!state.customer.firstName) {
      navigate('../contacts')
    }
  })

  // Redirect the user here to finalize the order.
  // Some payment providers require a redirect.
  const callbackUrl = `${config.apiUrl}/callback/orders/create/${state.id}`

  const handleError = (error: CombinedError | Error) => {
    console.error(error)
    setIsLoading(false)
    toast({
      title: phrases.error.title,
      description: error.message,
      variant: 'destructive',
    })
  }

  const storeOrderState = async (extra?: { paymentReference: string }) => {
    const res = await storeOrder({
      input: {
        orderId: state.id,
        paymentMethod: state.paymentMethod,
        bookings: state.bookings,
        customer: state.customer,
        termsAccepted: state.termsAccepted,
        ...extra,
      },
    })
    if (res.error) {
      handleError(res.error)
      return
    }
    return res
  }

  const onSubmit = async () => {
    if (!state.termsAccepted) {
      setTermsError('Du må godta kjøpsvilkår og personvernerklæring')
      return
    }
    setTermsError('')
    setIsLoading(true)

    if (state.paymentMethod === PaymentMethod.None) {
      await storeOrderState()
      window.location.href = callbackUrl
      return
    }

    if (
      state.paymentMethod === PaymentMethod.Vipps ||
      state.paymentMethod === PaymentMethod.Card
    ) {
      const res = await createPaymentIntent({
        input: {
          orderId: state.id,
          paymentMethod: state.paymentMethod,
          customer: state.customer,
          bookings: state.bookings.map((booking) => ({
            termId: booking.termId,
            reservationId: booking.reservationId,
          })),
        },
      })
      if (
        res.error ||
        !res.data?.createPaymentIntent.redirectUrl ||
        !res.data?.createPaymentIntent.reference
      ) {
        handleError(
          res.error || new Error('No redirectUrl or reference retrieved')
        )
        return
      }

      await storeOrderState({
        paymentReference: res.data.createPaymentIntent.reference,
      })
      window.location.href = res.data.createPaymentIntent.redirectUrl
      return
    }
  }

  const hasWaitinglistBooking = state.reservations.some((r) => !r.accepted)
  const depositTotal = sumBy(state.bookings, (b) => {
    const term = availableTerms.find((t) => t.id === b.termId)
    const reservation = state.reservations.find((r) => r.id === b.reservationId)
    return reservation?.deposit ?? term?.deposit ?? 0
  })

  return (
    <>
      <ExpiryNotice />
      <Content>
        <InfoNotice>
          <div className="flex items-start place-content-between">
            <p>Totalt til betaling:</p>
            <div>
              <p>
                <span className="font-bold">
                  {formatCurrency(depositTotal)}
                </span>
              </p>
            </div>
          </div>
        </InfoNotice>
        <fieldset>
          <legend className="font-semibold leading-6 mb-4">Betaling</legend>
          <PaymentElement
            availablePaymentMethods={availablePaymentMethods}
            showWaitinglistNotice={hasWaitinglistBooking}
            paymentMethod={state.paymentMethod}
            setPaymentMethod={(v) =>
              dispatch({
                type: 'setPaymentMethod',
                data: v,
              })
            }
          />

          <div className="flex flex-col mt-4 space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="termsAccepted"
                checked={state.termsAccepted}
                onCheckedChange={(v) =>
                  dispatch({ type: 'setTermsAccepted', data: !!v })
                }
              />
              <Label htmlFor="termsAccepted">
                Jeg godtar{' '}
                <Link
                  to="https://www.oksnoen.no/kjopsvilkar"
                  target="_blank"
                  className="underline text-#222"
                >
                  kjøpsvilkår
                </Link>{' '}
                og{' '}
                <Link
                  to="https://www.oksnoen.no/personvernerkaering"
                  target="_blank"
                  className="underline text-#222"
                >
                  personvernerklæring
                </Link>
                .
              </Label>
            </div>
            {termsError && (
              <p className="text-[0.8rem] font-medium text-red-500 dark:text-red-900">
                {termsError}
              </p>
            )}
          </div>
        </fieldset>

        <BottomNavbar className="justify-between">
          <Button asChild variant="ghost">
            <Link to="../contacts">Gå tilbake</Link>
          </Button>

          <Button type="button" onClick={onSubmit} disabled={isLoading}>
            Fullfør bestilling
          </Button>
        </BottomNavbar>
      </Content>
    </>
  )
}
