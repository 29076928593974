/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment TermCard on Term {\n    id\n    name\n    price\n    startsAt\n    endsAt\n    isFullyBooked\n  }\n": types.TermCardFragmentDoc,
    "\n  mutation CreateOrderPaymentIntent($input: CreatePaymentIntentInput!) {\n    createPaymentIntent(input: $input) {\n      redirectUrl\n      reference\n    }\n  }\n": types.CreateOrderPaymentIntentDocument,
    "\n  fragment NewOrderTermCard on Term {\n    id\n    ...TermCard\n  }\n": types.NewOrderTermCardFragmentDoc,
    "\n  mutation CreateReservations($input: CreateReservationsInput!) {\n    createReservations(input: $input) {\n      webOrder {\n        id\n        reservations {\n          id\n          termId\n          accepted\n          expiresAt\n          deposit\n        }\n      }\n    }\n  }\n": types.CreateReservationsDocument,
    "\n  mutation CancelReservations($input: CancelReservationsInput!) {\n    cancelReservations(input: $input) {\n      webOrder {\n        id\n        reservations {\n          id\n          termId\n          accepted\n          expiresAt\n          deposit\n        }\n      }\n    }\n  }\n": types.CancelReservationsDocument,
    "\n  query OrderContext($orderId: ID!) {\n    webOrder(id: $orderId) {\n      id\n      verifyOrderId\n      availablePaymentMethods\n      availableTerms {\n        id\n        targetGroup\n        deposit\n        ...NewOrderTermCard\n      }\n      reservations {\n        id\n        termId\n        accepted\n        expiresAt\n        deposit\n      }\n      draftOrderState\n    }\n  }\n": types.OrderContextDocument,
    "\n  query Seasons {\n    seasons {\n      id\n      name\n      registrationOpensAt\n    }\n  }\n": types.SeasonsDocument,
    "\n  mutation StoreOrder($input: StoreOrderInput!) {\n    storeOrder(input: $input) {\n      value\n    }\n  }\n": types.StoreOrderDocument,
    "\n  query OrderConfirmation($orderId: ID!, $signature: String!) {\n    order(id: $orderId, signature: $signature) {\n      id\n      bookings {\n        id\n        number\n        firstName\n        lastName\n        dob\n        gender\n        friends\n        notes\n        pastVisits\n        sweaterSize\n        creditLimit\n        reservation {\n          id\n          accepted\n        }\n        term {\n          id\n          targetGroup\n          ...TermCard\n        }\n      }\n    }\n  }\n": types.OrderConfirmationDocument,
    "\n  query booking($id: ID!, $signature: String!) {\n    booking(id: $id, signature: $signature) {\n      id\n      number\n      firstName\n      lastName\n      dob\n      gender\n      friends\n      notes\n      pastVisits\n      sweaterSize\n      creditLimit\n      status\n      term {\n        id\n        targetGroup\n        startsAt\n      }\n      order {\n        id\n      }\n      reservation {\n        id\n        accepted\n      }\n      depositPayment {\n        id\n        state\n        amount\n        updatedAt\n        data {\n          reference\n        }\n      }\n      ...BookingCardHeader\n    }\n  }\n": types.BookingDocument,
    "\n  fragment BookingCardHeader on Booking {\n    id\n    number\n    status\n    price\n    term {\n      id\n      name\n      startsAt\n      endsAt\n    }\n  }\n": types.BookingCardHeaderFragmentDoc,
    "\n  fragment ContactPerson on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n": types.ContactPersonFragmentDoc,
    "\n  fragment Customer on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n": types.CustomerFragmentDoc,
    "\n  mutation DeleteContactPerson($id: ID!, $signature: String) {\n    deleteContactPerson(id: $id, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n": types.DeleteContactPersonDocument,
    "\n  mutation UpdateBooking($id: ID!, $input: UpdateBookingInput!, $signature: String) {\n    updateBooking(id: $id, input: $input, signature: $signature) {\n      booking {\n        id\n        firstName\n        lastName\n        dob\n        gender\n        friends\n        notes\n        pastVisits\n        sweaterSize\n        creditLimit\n      }\n    }\n  }\n": types.UpdateBookingDocument,
    "\n  mutation CreateContactPerson($input: ContactPersonInput!, $signature: String) {\n    createContactPerson(input: $input, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n": types.CreateContactPersonDocument,
    "\n  fragment UpdateContactPersonData on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n": types.UpdateContactPersonDataFragmentDoc,
    "\n  query UpdateContactPersonData($orderId: ID!) {\n    order(id: $orderId) {\n        id\n        contactPersons {\n          id\n          ...UpdateContactPersonData\n        }\n      }\n  }\n": types.UpdateContactPersonDataDocument,
    "\n  mutation UpdateContactPerson($id: ID!, $input: UpdateContactPersonInput!) {\n    updateContactPerson(id: $id, input: $input) {\n      contactPerson {\n        id\n        ...UpdateContactPersonData\n      }\n    }\n  }\n": types.UpdateContactPersonDocument,
    "\n  fragment UpdateCustomerData on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n": types.UpdateCustomerDataFragmentDoc,
    "\n  query UpdateCustomerData($orderId: ID!, $signature: String!) {\n    order(id: $orderId, signature: $signature) {\n      id\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n": types.UpdateCustomerDataDocument,
    "\n  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!, $signature: String!) {\n    updateCustomer(orderId: $orderId, input: $input, signature: $signature) {\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n": types.UpdateCustomerDocument,
    "\n  query Order($id: ID!, $signature: String!) {\n    order(id: $id, signature: $signature) {\n      id\n      customer {\n          ...Customer\n        }\n      contactPersons {\n        ...ContactPerson\n      }\n      bookings {\n        id\n        firstName\n        lastName\n        status\n        term {\n          id\n          targetGroup\n        }\n        ...BookingCardHeader\n      }\n    }\n  }\n": types.OrderDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TermCard on Term {\n    id\n    name\n    price\n    startsAt\n    endsAt\n    isFullyBooked\n  }\n"): (typeof documents)["\n  fragment TermCard on Term {\n    id\n    name\n    price\n    startsAt\n    endsAt\n    isFullyBooked\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOrderPaymentIntent($input: CreatePaymentIntentInput!) {\n    createPaymentIntent(input: $input) {\n      redirectUrl\n      reference\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrderPaymentIntent($input: CreatePaymentIntentInput!) {\n    createPaymentIntent(input: $input) {\n      redirectUrl\n      reference\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NewOrderTermCard on Term {\n    id\n    ...TermCard\n  }\n"): (typeof documents)["\n  fragment NewOrderTermCard on Term {\n    id\n    ...TermCard\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateReservations($input: CreateReservationsInput!) {\n    createReservations(input: $input) {\n      webOrder {\n        id\n        reservations {\n          id\n          termId\n          accepted\n          expiresAt\n          deposit\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateReservations($input: CreateReservationsInput!) {\n    createReservations(input: $input) {\n      webOrder {\n        id\n        reservations {\n          id\n          termId\n          accepted\n          expiresAt\n          deposit\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelReservations($input: CancelReservationsInput!) {\n    cancelReservations(input: $input) {\n      webOrder {\n        id\n        reservations {\n          id\n          termId\n          accepted\n          expiresAt\n          deposit\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CancelReservations($input: CancelReservationsInput!) {\n    cancelReservations(input: $input) {\n      webOrder {\n        id\n        reservations {\n          id\n          termId\n          accepted\n          expiresAt\n          deposit\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderContext($orderId: ID!) {\n    webOrder(id: $orderId) {\n      id\n      verifyOrderId\n      availablePaymentMethods\n      availableTerms {\n        id\n        targetGroup\n        deposit\n        ...NewOrderTermCard\n      }\n      reservations {\n        id\n        termId\n        accepted\n        expiresAt\n        deposit\n      }\n      draftOrderState\n    }\n  }\n"): (typeof documents)["\n  query OrderContext($orderId: ID!) {\n    webOrder(id: $orderId) {\n      id\n      verifyOrderId\n      availablePaymentMethods\n      availableTerms {\n        id\n        targetGroup\n        deposit\n        ...NewOrderTermCard\n      }\n      reservations {\n        id\n        termId\n        accepted\n        expiresAt\n        deposit\n      }\n      draftOrderState\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Seasons {\n    seasons {\n      id\n      name\n      registrationOpensAt\n    }\n  }\n"): (typeof documents)["\n  query Seasons {\n    seasons {\n      id\n      name\n      registrationOpensAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation StoreOrder($input: StoreOrderInput!) {\n    storeOrder(input: $input) {\n      value\n    }\n  }\n"): (typeof documents)["\n  mutation StoreOrder($input: StoreOrderInput!) {\n    storeOrder(input: $input) {\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrderConfirmation($orderId: ID!, $signature: String!) {\n    order(id: $orderId, signature: $signature) {\n      id\n      bookings {\n        id\n        number\n        firstName\n        lastName\n        dob\n        gender\n        friends\n        notes\n        pastVisits\n        sweaterSize\n        creditLimit\n        reservation {\n          id\n          accepted\n        }\n        term {\n          id\n          targetGroup\n          ...TermCard\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OrderConfirmation($orderId: ID!, $signature: String!) {\n    order(id: $orderId, signature: $signature) {\n      id\n      bookings {\n        id\n        number\n        firstName\n        lastName\n        dob\n        gender\n        friends\n        notes\n        pastVisits\n        sweaterSize\n        creditLimit\n        reservation {\n          id\n          accepted\n        }\n        term {\n          id\n          targetGroup\n          ...TermCard\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query booking($id: ID!, $signature: String!) {\n    booking(id: $id, signature: $signature) {\n      id\n      number\n      firstName\n      lastName\n      dob\n      gender\n      friends\n      notes\n      pastVisits\n      sweaterSize\n      creditLimit\n      status\n      term {\n        id\n        targetGroup\n        startsAt\n      }\n      order {\n        id\n      }\n      reservation {\n        id\n        accepted\n      }\n      depositPayment {\n        id\n        state\n        amount\n        updatedAt\n        data {\n          reference\n        }\n      }\n      ...BookingCardHeader\n    }\n  }\n"): (typeof documents)["\n  query booking($id: ID!, $signature: String!) {\n    booking(id: $id, signature: $signature) {\n      id\n      number\n      firstName\n      lastName\n      dob\n      gender\n      friends\n      notes\n      pastVisits\n      sweaterSize\n      creditLimit\n      status\n      term {\n        id\n        targetGroup\n        startsAt\n      }\n      order {\n        id\n      }\n      reservation {\n        id\n        accepted\n      }\n      depositPayment {\n        id\n        state\n        amount\n        updatedAt\n        data {\n          reference\n        }\n      }\n      ...BookingCardHeader\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BookingCardHeader on Booking {\n    id\n    number\n    status\n    price\n    term {\n      id\n      name\n      startsAt\n      endsAt\n    }\n  }\n"): (typeof documents)["\n  fragment BookingCardHeader on Booking {\n    id\n    number\n    status\n    price\n    term {\n      id\n      name\n      startsAt\n      endsAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ContactPerson on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n"): (typeof documents)["\n  fragment ContactPerson on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment Customer on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"): (typeof documents)["\n  fragment Customer on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteContactPerson($id: ID!, $signature: String) {\n    deleteContactPerson(id: $id, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteContactPerson($id: ID!, $signature: String) {\n    deleteContactPerson(id: $id, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateBooking($id: ID!, $input: UpdateBookingInput!, $signature: String) {\n    updateBooking(id: $id, input: $input, signature: $signature) {\n      booking {\n        id\n        firstName\n        lastName\n        dob\n        gender\n        friends\n        notes\n        pastVisits\n        sweaterSize\n        creditLimit\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateBooking($id: ID!, $input: UpdateBookingInput!, $signature: String) {\n    updateBooking(id: $id, input: $input, signature: $signature) {\n      booking {\n        id\n        firstName\n        lastName\n        dob\n        gender\n        friends\n        notes\n        pastVisits\n        sweaterSize\n        creditLimit\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateContactPerson($input: ContactPersonInput!, $signature: String) {\n    createContactPerson(input: $input, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateContactPerson($input: ContactPersonInput!, $signature: String) {\n    createContactPerson(input: $input, signature: $signature) {\n      order {\n        id\n        contactPersons {\n          id\n          firstName\n          lastName\n          email\n          phone\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UpdateContactPersonData on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n"): (typeof documents)["\n  fragment UpdateContactPersonData on ContactPerson {\n    id\n    firstName\n    lastName\n    email\n    phone\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UpdateContactPersonData($orderId: ID!) {\n    order(id: $orderId) {\n        id\n        contactPersons {\n          id\n          ...UpdateContactPersonData\n        }\n      }\n  }\n"): (typeof documents)["\n  query UpdateContactPersonData($orderId: ID!) {\n    order(id: $orderId) {\n        id\n        contactPersons {\n          id\n          ...UpdateContactPersonData\n        }\n      }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateContactPerson($id: ID!, $input: UpdateContactPersonInput!) {\n    updateContactPerson(id: $id, input: $input) {\n      contactPerson {\n        id\n        ...UpdateContactPersonData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateContactPerson($id: ID!, $input: UpdateContactPersonInput!) {\n    updateContactPerson(id: $id, input: $input) {\n      contactPerson {\n        id\n        ...UpdateContactPersonData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UpdateCustomerData on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"): (typeof documents)["\n  fragment UpdateCustomerData on Customer {\n    id\n    firstName\n    lastName\n    email\n    phone\n    address {\n      streetAddress\n      postalCode\n      postalArea\n    }\n    postAddress {\n      streetAddress\n      postalCode\n      postalArea\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UpdateCustomerData($orderId: ID!, $signature: String!) {\n    order(id: $orderId, signature: $signature) {\n      id\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n"): (typeof documents)["\n  query UpdateCustomerData($orderId: ID!, $signature: String!) {\n    order(id: $orderId, signature: $signature) {\n      id\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!, $signature: String!) {\n    updateCustomer(orderId: $orderId, input: $input, signature: $signature) {\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCustomer($orderId: ID!, $input: CustomerInput!, $signature: String!) {\n    updateCustomer(orderId: $orderId, input: $input, signature: $signature) {\n      customer {\n        id\n        ...UpdateCustomerData\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Order($id: ID!, $signature: String!) {\n    order(id: $id, signature: $signature) {\n      id\n      customer {\n          ...Customer\n        }\n      contactPersons {\n        ...ContactPerson\n      }\n      bookings {\n        id\n        firstName\n        lastName\n        status\n        term {\n          id\n          targetGroup\n        }\n        ...BookingCardHeader\n      }\n    }\n  }\n"): (typeof documents)["\n  query Order($id: ID!, $signature: String!) {\n    order(id: $id, signature: $signature) {\n      id\n      customer {\n          ...Customer\n        }\n      contactPersons {\n        ...ContactPerson\n      }\n      bookings {\n        id\n        firstName\n        lastName\n        status\n        term {\n          id\n          targetGroup\n        }\n        ...BookingCardHeader\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;