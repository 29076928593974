import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { graphql } from '@/__generated__/gql'
import { groupBy } from '@/utils/group-by'
import { phrases } from '@/utils/phrases'
import { formatDate } from '@/utils/format-date'
import { Content, Layout } from '@/components/layout'
import { Loading } from '@/components/loading'
import { QueryError } from '@/components/query-error'
import { Dd, Dl, Dt } from '@/components/ui/dl'
import { TargetGroupHeading } from '@/components/target-group-heading'
import { TermCard } from '@/components/term-card'
import { BottomNavbar } from '@/components/bottom-navbar'
import { Button } from '@/components/ui/button'
import FireIcon from '@/assets/fire.svg'

const orderConfirmationDocument = graphql(`
  query OrderConfirmation($orderId: ID!, $signature: String!) {
    order(id: $orderId, signature: $signature) {
      id
      bookings {
        id
        number
        firstName
        lastName
        dob
        gender
        friends
        notes
        pastVisits
        sweaterSize
        creditLimit
        reservation {
          id
          accepted
        }
        term {
          id
          targetGroup
          ...TermCard
        }
      }
    }
  }
`)

export const OrderConfirmationRoute = () => {
  const params = useParams<'signature' | 'orderId'>()
  const [{ data, fetching, error }] = useQuery({
    query: orderConfirmationDocument,
    variables: { orderId: params.orderId!, signature: params.signature! },
  })

  const bookings = data?.order.bookings ?? []
  const groupedByTerm = groupBy(bookings, (b) => b.term.id)

  const allBookingsAccepted = !!data?.order?.bookings.every(
    (b) => b.reservation.accepted
  )
  const everyoneHasWaitList = !!data?.order.bookings.every(
    (b) => !b.reservation.accepted
  )

  const underlineClasses = 'border-b-4 border-sky-200 inline-block h-[2.1rem]'

  return (
    <Layout>
      <Content>
        {fetching && <Loading />}
        {error && <QueryError error={error} />}
        {!!data && (
          <>
            <h1 className="mb-6 text-3xl font-extrabold text-center">
              {allBookingsAccepted ? (
                <>
                  Du har fått <span className={underlineClasses}>plass</span>
                </>
              ) : everyoneHasWaitList ? (
                <>
                  Du er på <span className={underlineClasses}>venteliste</span>
                </>
              ) : (
                <>Din bestilling er mottatt!</>
              )}
            </h1>
            <div className="flex flex-col items-center w-100%">
              <img
                src={FireIcon}
                alt="Fireplace"
                className="justify-self-center"
                width={100}
              />
            </div>
            <div className="text-center pb-4 mb-4 border-b-2 border-dashed">
              {allBookingsAccepted ? (
                <>
                  <p className="text-lg font-bold">
                    Bestillingen din er mottatt.
                  </p>
                  <p>
                    Vi kan med glede meddele at{' '}
                    {data.order.bookings
                      .map(
                        (booking) => `${booking.firstName} ${booking.lastName}`
                      )
                      .join(', ')}{' '}
                    har fått plass på leir.
                  </p>
                  <p>
                    Vi sender deg en e-post med bekreftelse og en e-post til når
                    forhåndsbetalingen er bekreftet.
                  </p>
                </>
              ) : everyoneHasWaitList ? (
                <>
                  <p>Vi sender deg en e-post med bekreftelse.</p>
                  <p>
                    Siden du står på venteliste, får du også en e-post når det
                    eventuelt blir ledig plass. Når plassen blir bekreftet, blir
                    reservert beløpet på 900 kr per bekreftet deltaker for
                    forhåndsbetaling trukket automatisk.
                  </p>
                </>
              ) : (
                <>
                  <p className="text-lg font-bold">
                    Forhåndsbetaling behandles.
                  </p>
                  <p>Vi sender deg en e-post med bekreftelse.</p>
                  <p>
                    Dersom du står på venteliste, får du også en e-post når det
                    eventuelt blir ledig plass. Når plassen blir bekreftet, blir
                    reservert beløpet på 900 kr per bekreftet deltaker for
                    forhåndsbetaling trukket automatisk.
                  </p>
                </>
              )}
            </div>

            <div className="flex flex-col gap-4">
              {Object.entries(groupedByTerm).map(([termId, bookings]) => (
                <div key={termId}>
                  <TargetGroupHeading
                    targetGroup={bookings[0].term.targetGroup}
                  />
                  <TermCard
                    term={bookings[0].term}
                    isAccepted={bookings.every((b) => b.reservation.accepted)}
                    isWaitingList={bookings.every(
                      (b) => !b.reservation.accepted
                    )}
                    className="mb-4"
                  />
                  <div className="px-6">
                    {bookings.map((booking, i) => (
                      <div key={booking.id}>
                        <p className={`mb-2 font-bold ${i > 0 && 'mt-6'}`}>
                          Booking #{booking.number}
                        </p>
                        <Dl>
                          <Dt>Navn</Dt>
                          <Dd>
                            {booking.firstName} {booking.lastName}
                          </Dd>
                          <Dt>Fødselsdato</Dt>
                          <Dd>{formatDate(booking.dob, { style: 'short' })}</Dd>
                          <Dt>Kjønn</Dt>
                          <Dd>{phrases.genders[booking.gender]}</Dd>
                          <Dt>Ønsker å bo med</Dt>
                          <Dd>{booking.friends || '-'}</Dd>
                          <Dt>Særlige opplysninger</Dt>
                          <Dd>{booking.notes || '-'}</Dd>
                          <Dt>Deltatt tidligere (antall år)</Dt>
                          <Dd>{booking.pastVisits}</Dd>
                          <Dt>Genser</Dt>
                          <Dd>
                            {booking.sweaterSize?.toUpperCase() ?? 'Ingen'}
                          </Dd>
                          <Dt>Kioskpenger</Dt>
                          <Dd>{booking.creditLimit ?? '-'}</Dd>
                        </Dl>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Content>
      <BottomNavbar className="justify-between">
        <Button asChild variant="ghost">
          <Link to="https://www.oksnoen.no/">Gå til oksnoen.no</Link>
        </Button>

        <Button asChild type="button">
          <Link to="/">Ny booking</Link>
        </Button>
      </BottomNavbar>
    </Layout>
  )
}
