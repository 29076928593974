import { useCallback } from 'react'
import {
  DeepPartial,
  FieldArrayWithId,
  UseFormReturn,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { formatDate } from '@/utils/format-date'
import { schemas } from '@/utils/schemas'
import { useDobValidator } from '@/hooks/use-dob-validator'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'
import { TargetGroupHeading } from '@/components/target-group-heading'
import { useToast } from '@/components/ui/use-toast'
import { GenderRadioGroup } from '@/components/gender-radio-group'
import { PastVisitsSelect } from '@/components/past-visits-select'
import { useOrderContext } from './order-context'
import { NewOrderTermCard } from './new-order-term-card'

type Props = {
  defaultValues?: DeepPartial<FormValues>
  onSubmit: (input: FormValues) => void | Promise<void>
}

const formSchema = z.object({
  bookings: z
    .array(
      z.object({
        termId: z.string(),
        reservationId: z.string(),
        firstName: schemas.firstName,
        lastName: schemas.lastName,
        dob: schemas.dob,
        gender: schemas.gender,
        pastVisits: schemas.pastVisits,
        friends: schemas.friends,
        notes: schemas.notes,
        sweaterSize: schemas.sweaterSize,
        creditLimit: schemas.creditLimit,
        canSwim: z.literal<boolean>(true, {
          errorMap: () => ({ message: 'Svømmedyktighet må bekreftes' }),
        }),
        notBringingPhone: z.literal<boolean>(true, {
          errorMap: () => ({
            message: 'Må bekrefte at mobiltelefon ikke medbringes',
          }),
        }),
        legalGuardian: z.literal<boolean>(true, {
          errorMap: () => ({
            message: 'Må bekrefte at du kan påmelde denne deltalkeren',
          }),
        }),
      })
    )
    .min(1),
})

export type FormValues = z.infer<typeof formSchema>

export const BookingsForm = ({ defaultValues, onSubmit }: Props) => {
  const { toast } = useToast()
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })
  const { control, handleSubmit } = form
  const { fields: bookings } = useFieldArray({
    control,
    name: 'bookings',
  })

  const onInvalid = useCallback(() => {
    toast({
      description:
        'Noen obligatoriske felt mangler, vennligst se gjennom skjemaet.',
      variant: 'destructive',
    })
  }, [])

  return (
    <Form {...form}>
      <form
        id="bookings-form"
        onSubmit={handleSubmit(onSubmit, onInvalid)}
        className="flex flex-col gap-4"
      >
        {bookings.map((field, i) => (
          <BookingsFormBooking
            key={field.id}
            form={form}
            field={field}
            i={i}
            shouldRenderHeading={
              // Render heading only once per term
              i === bookings.findIndex((b) => b.termId === field.termId)
            }
          />
        ))}
      </form>
    </Form>
  )
}

const BookingsFormBooking = ({
  i,
  field,
  form,
  shouldRenderHeading,
}: {
  i: number
  field: FieldArrayWithId<FormValues, 'bookings', 'id'>
  form: UseFormReturn<FormValues>
  shouldRenderHeading: boolean
}) => {
  const { availableTerms } = useOrderContext()
  const term = availableTerms.find((t) => t.id === field.termId)!

  const { minDob, maxDob } = useDobValidator(form, term)

  const { errors } = form.formState

  return (
    <>
      {shouldRenderHeading && (
        <>
          <TargetGroupHeading targetGroup={term.targetGroup} className="mb-1" />
          <NewOrderTermCard term={term} className="mb-2" />
        </>
      )}

      <fieldset key={field.id} className="flex flex-col gap-5 mb-4">
        <legend className="font-semibold leading-6">Deltaker {i + 1}</legend>

        <FormField
          control={form.control}
          name={`bookings.${i}.firstName`}
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Fornavn</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  isError={Boolean(errors.bookings?.[i]?.firstName)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.lastName`}
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Etternavn</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  isError={Boolean(errors.bookings?.[i]?.lastName)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.dob`}
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Fødselsdato</FormLabel>
              <FormDescription>
                Deltaker må være født mellom{' '}
                {formatDate(minDob, { style: 'short' })} og{' '}
                {formatDate(maxDob, { style: 'short' })}
              </FormDescription>
              <FormControl>
                <Input
                  min={minDob}
                  max={maxDob}
                  type="date"
                  {...field}
                  isError={Boolean(errors.bookings?.[i]?.dob)}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.gender`}
          render={({ field }) => (
            <FormItem>
              <Label required>Kjønn</Label>
              <FormControl>
                <GenderRadioGroup
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.pastVisits`}
          render={({ field }) => (
            <FormItem>
              <FormLabel required>Deltatt tidligere?</FormLabel>
              <FormDescription>
                Hvor mange år har deltaker deltatt før
              </FormDescription>
              <PastVisitsSelect value={field.value} onChange={field.onChange} />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.friends`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Ønsker å bo med</FormLabel>
              <FormDescription>Navn på noen du ønsker å bo med</FormDescription>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.notes`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Særlige opplysninger</FormLabel>
              <FormDescription>
                Informasjon om deltaker som Oksnøen Leir bør vite om
              </FormDescription>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.creditLimit`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Kioskpenger</FormLabel>
              <FormDescription>
                Beløpsgrense for deltaker i kiosken
              </FormDescription>
              <FormControl>
                <Input
                  {...field}
                  type="number"
                  min={0}
                  value={field.value ?? ''}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value ? Number(e.target.value) : null
                    )
                  }
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.canSwim`}
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center space-x-3">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(value) => field.onChange(!!value)}
                  />
                </FormControl>
                <FormLabel required className="font-normal text-base">
                  Deltakeren er svømmedyktig
                </FormLabel>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.notBringingPhone`}
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center space-x-3">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(value) => field.onChange(!!value)}
                  />
                </FormControl>
                <FormLabel required className="font-normal text-base">
                  Deltakeren har ikke med seg mobiltelefon. Dette er et
                  mobilfritt arrangement
                </FormLabel>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`bookings.${i}.legalGuardian`}
          render={({ field }) => (
            <FormItem>
              <div className="flex items-center space-x-3">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={(value) => field.onChange(!!value)}
                  />
                </FormControl>
                <FormLabel required className="font-normal text-base">
                  Jeg bekrefter at jeg er foresatt eller verge for det
                  registrerte barnet og at all oppgitt informasjon er korrekt
                </FormLabel>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </fieldset>
    </>
  )
}
