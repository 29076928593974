import { graphql } from '@/__generated__/gql'
import { useQuery } from 'urql'
import { formatDate } from '@/utils/format-date'
import { Content } from '@/components/layout'
import { InfoNotice } from './components/info-notice'

const seasonsDocument = graphql(`
  query Seasons {
    seasons {
      id
      name
      registrationOpensAt
    }
  }
`)

export const NoTermsAvailable = () => {
  const [{ data }] = useQuery({
    query: seasonsDocument,
  })
  const selectedSeason = data?.seasons[0]

  return (
    <Content>
      <InfoNotice>
        <div className="text-center">
          <p>Det er ingen tilgjengelige perioder akkurat nå.</p>
          {selectedSeason && (
            <p>
              Booking av perioder for {selectedSeason.name} åpnes{' '}
              {formatDate(selectedSeason.registrationOpensAt, {
                style: 'time',
              })}{' '}
              (Oslo, Norge).
            </p>
          )}
        </div>
      </InfoNotice>
    </Content>
  )
}
